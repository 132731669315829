<template>
  <div class="authentication">
    <div class="content">
      <div class="title">
        <h3>兰州市残疾人就业创业网络服务平台 | 信息审核</h3>
        <router-link to="/">返回首页</router-link>
      </div>
      <div class="install">
        <h1>企业信息审核</h1>
        <p>为验证企业真实性，请您提交相关信息</p>
        <el-form :model="form" :rules="rules" label-width="160px" ref="form" class="form">
          <el-form-item label="统一社会信用代码：" prop="socialCredit">
            <el-input v-model="form.socialCredit" class="w410" placeholder="统一社会信用代码"></el-input>
          </el-form-item>
          <el-form-item class="business_license" label="营业执照：" prop="licenseUrl">
            <div>
              <div>
                <el-upload class="avatar-uploader uploadimgs upload-demo" action="/api/app-jycy-disabled/uploadImg" :on-remove="handleRemove" :headers="uploadHeaders" list-type="picture-card" :show-file-list="false" :on-success="async (res)=>{handleAvatarlicenseUrl(res,'licenseUrl')}" :on-error="erroupload" accept=".png,.jpg" :before-upload="beforeAvatarUpload">
                  <img v-if="form.licenseUrl" :src="form.licenseUrl" class="img_avatar block_inline" width="148" height="148" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过2MB</div>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="tips">
              如果您是人力资源机构，请必须上传 人力资源服务许可证、劳务派遣经营许可证，否则无法审核通过。
            </div>
          </el-form-item>
          <el-form-item class="business_license" label="人力资源服务许可证：">
            <div>
              <div>
                <el-upload class="avatar-uploader uploadimgs upload-demo" action="/api/app-jycy-disabled/uploadImg" :headers="uploadHeaders" :on-remove="handleRemove" list-type="picture-card" :show-file-list="false" accept=".png,.jpg" :on-success="async (res)=>{handleAvatarlicenseUrl(res,'hrUrl')}" :on-error="erroupload" :before-upload="beforeAvatarUpload">
                  <img v-if="form.hrUrl" :src="form.hrUrl" class="img_avatar block_inline" width="148" height="148" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过2MB</div>
              </div>
            </div>
          </el-form-item>
          <el-form-item class="business_license" label="劳务派遣经营许可证：">
            <div>
              <div>
                <el-upload class="avatar-uploader uploadimgs upload-demo" action="/api/app-jycy-disabled/uploadImg" :headers="uploadHeaders" :on-remove="handleRemove" list-type="picture-card" :show-file-list="false" accept=".png,.jpg" :on-success="async (res)=>{handleAvatarlicenseUrl(res,'ldUrl')}" :on-error="erroupload" :before-upload="beforeAvatarUpload">
                  <img v-if="form.ldUrl" :src="form.ldUrl" class="img_avatar block_inline" width="148" height="148" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过2MB</div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="行业领域：" prop="hyTop" style="background-color: #FFFFFF;">
            <ThreeLink class="w410" :codeType="'hy1'" :show_level="false" :codeLevel="'2'" @setMValue="setScasValue($event,form,['hyTop','hy'])" :defaultValue="[form.hyTop,form.hy]"></ThreeLink>
          </el-form-item>

          <el-form-item label="企业类型：" prop="pr">
            <MulSelect class="w410" :code="'pr'" @setMValue="setSelectValue($event,'pr')" :defaultValue="form.pr">>
            </MulSelect>
          </el-form-item>
          <el-form-item label="企业规模：" class="text_L" prop="mun">
            <MulSelect class="w410" :code="'mun'" @setMValue="setSelectValue($event,'mun')" :defaultValue="form.mun">>
            </MulSelect>
          </el-form-item>
          <el-form-item label="公司简介" class="business_license" prop="content">
            <Editor style="width:610px;" placeholder="详细的公司简介，有助于帮助应聘者了解您公司，吸引更多人才投递简历。" @html="text" :testcontent="form.content" height="400"></Editor>
          </el-form-item>
        </el-form>
        <div class="btn">
          <el-button type="primary" @click="auditStatuSubmit('form')">提交审核</el-button>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="isShow" width="312px" :show-close="false" class="wrap_input" :before-close="handleClose">
      <div class="dialog_content" v-if="active == 0">
        <img src="~/static/login/success.png" alt="">
        <h3>提交审核成功</h3>
        <!-- <p>恭喜您通过认证，可以获取更多服务啦</p> -->
        <el-button type="primary" @click="info()">返回({{ time }}S)</el-button>
      </div>
      <div class="dialog_content" v-if="active == 1">
        <img src="~/static/login/error.png" alt="">
        <h3>认证失败</h3>
        <p>{{ message }}</p>
        <el-button type="primary">返回({{ time }}S)</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="isHide" width="312px" :show-close="false" :before-close="handleClose">
      <div class="dialog_content">
        <img src="~/static/login/resume.png" alt="">
        <h3>未通过认证的用户 <br>
          无法享受企业专属服务哦</h3>
        <div class="btn flex">
          <el-button type="info" @click="goRouter('/')">残忍拒绝</el-button>
          <el-button type="primary" @click="isHide = false">去认证</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ThreeLink from '@/components/selectComponents/ThreeLink'
import MulSelect from '@/components/selectComponents/MulSelect'
import { companyNeedAudit } from '@/api/enterpriseEnter'
import Editor from "@/components/public/editor.vue";

export default {
  name: 'authentication',
  components: { ThreeLink, MulSelect,Editor },
  inject: ['reload'],
  data() {
    return {
      form: {
        companyName: '',
        pr: '',
        mun: '',
        logo: '',
        licenseUrl: '',
        hyTop: '',
        hy: '',
        isHr: false,
        hrUrl: '',
        ldUrl: '',
        socialCredit: '',
        provinceid: 'p17',
        cityid: 'c214',
        threeCityid: '',
        address: '',
        auditStatus: '',
        content:''
      },
      rules: {
        companyName: [
          { required: true, message: '必填', trigger: 'blur,change' },
        ],
        pr: [{ required: true, message: '必填', trigger: 'blur,change' }],
        mun: [{ required: true, message: '必填', trigger: 'blur,change' }],
        logo: [{ required: true, message: '必填', trigger: 'blur,change' }],
        licenseUrl: [
          { required: true, message: '必填', trigger: 'blur,change' },
        ],
        hyTop: [{ required: true, message: '必填', trigger: 'blur,change' }],
        socialCredit: [
          { required: true, message: '必填', trigger: 'blur,change' },
        ],
        cityid: [{ required: true, message: '必填', trigger: 'blur,change' }],
        content: [{ required: true, message: '必填', trigger: 'blur,change' }],
      },
      isShow: false,
      isHide: false,
      active: 1,
      time: 3,
      message: '很遗憾未通过认证，请检查后重新提交',
    }
  },
  methods: {
    text(val) {
      this.form.content = val;
    },
    handleRemove() {},
    handleAvatarlicenseUrl(res, type) {
      if (res.success) {
        this.form[type] = res.msg
      } else {
        this.form[type] = ''
        this.$message.error('图片上传失败')
      }
    },
    erroupload() {
      this.$message({
        message: '图片上传失败，请重新上传！！',
        center: true,
      })
    },
    beforeAvatarUpload(file) {
      let type = ['image/jpeg', 'image/png']
      const isImage = type.includes(file.type)
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isImage) {
        this.$message.error('上传图片格式不正确')
      }
      if (!isLt2M) {
        this.$message.error('图片大小不能超过 2MB!')
      }
      return isImage && isLt2M
    },
    setSelectValue(val, key) {
      this.form[key] = val
    },
    auditStatuSubmit(form) {
      this.$refs[form].validate(async (valid) => {
        if (valid) {
          let that = this
          that.form.isHr = that.form.hrUrl && that.form.ldUrl ? '1' : '0'
          this.$confirm(
              '请检查您的信息是否填写正确, 一旦确定,需审核通过才能进行下次更改！是否继续?',
              '提示',
              {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
              }
          ).then(() => {
            this.$api.companyNeedAudit(this.form).then((res) => {
              if (res.data.success) {
                this.$message.success('提交审核成功,请耐心等待审核结果！')
                this.active = 0
                this.isShow = true
                this.time = 3
                setTimeout(() => {
                  this.info()
                }, 2000)
              } else {
                this.$message.error(res.data.msg)
                return false
              }
            })
          })
        } else {
          //this.$message.error(res.data.msg);
          return false
        }
      })
      this.form.auditStatus = '1'
    },

    noAuthentication() {},
    handleClose() {
      this.isShow = false
      this.isHide = false
      if (this.active == 0) {
        this.info()
      }
    },

    goRouter(url, id) {
      const { href } = this.$router.resolve({
        path: url,
        id: id,
      })
      window.location.href = href
    },
    setScasValue(event, form, keys) {
      for (let i = 0; i < keys.length; i++) {
        let value = event[i]
        if (!value) {
          value = ''
        }
        let key = keys[i]
        form[key] = value
      }
    },
    async getOut() {
      this.$store.commit('SET_USER_INFO', 1)
      this.$store.commit('SET_TOKEN', '')
      this.$store.commit('GET_USER', '')
      localStorage.removeItem('access_token')
      localStorage.removeItem('userInfo')
      localStorage.removeItem('userType')
      this.$router.push('/user/companyLogin')
    },
    async getComInfo() {
      let that = this
      let res = await that.$api.getCompanyInfos()
      console.log(res)
      if (res.data.code === 200) {
        //that.form = res.data.data;
        that.form.companyName = res.data.data.companyName
        //that.form.linkphone = "";
      }
    },
    async info() {
      let res = await this.$api.loginRefreshApi()
      if (res.data.success) {
        let user = res.data.data
        this.$store.commit('SET_USER_INFO', user)
        this.$router.push('/')
        this.reload()
      } else {
        this.$message.error('登录失败')
      }
    },
  },
  mounted() {
    this.form.memberId = JSON.parse(localStorage.getItem('userType')).id
  },
  created() {
    this.getComInfo()
    //thres.data。is.from.companyName =
  },
}
</script>

<style scoped lang="less">
.el-input__suffix {
  right: 68px;
}

.authentication {
  width: 100%;
  height: 85%;
  background-color: white;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 366px;

  .content {
    width: 1200px;
    margin: 0 auto;

    .title {
      display: flex;
      padding-top: 26px;

      h3 {
        flex: 1;
        font-size: 18px;
        color: #28a46d;
      }

      a {
        font-size: 18px;
        color: #66716c;
      }

      a:hover {
        color: #28a46d;
      }
    }

    .install {
      width: 60%;
      margin: 20px auto 0 auto;
      text-align: center;
      padding-bottom: 20px;

      h1 {
        font-size: 28px;
        color: #00120a;
        margin-bottom: 10px;
      }

      p {
        font-size: 16px;
        color: #66716c;
      }

      /deep/ .form {
        margin: 36px auto 0 80px;
        width: 450px;
        .el-form-item {
          .tips {
            line-height: 1.5em;
            color: #ff9100;
          }
          .el-input__inner {
            width: 336px;
            background-color: #ffffff;
          }
          .el-textarea__inner::placeholder {
            font-size: 14px;
            color: #99a09d;
            font-family: apple-system, "PingFang SC", PingFangSC-Medium, "Microsoft YaHei", BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Source Han Sans SC", "Noto Sans CJK SC", "WenQuanYi Micro Hei", sans-serif;

          }
          .el-input__inner::placeholder {
            font-size: 14px;
            color: #99a09d;
          }
        }
      }

      .btn {
        margin-top: 15px;

        .el-button {
          display: block;
          margin: 0 auto;
          width: 336px;
        }

        .no_err {
          color: #99a09d;
        }
      }
    }
  }

  .el-dialog {
    .dialog_content {
      text-align: center;

      h3 {
        font-size: 18px;
        margin-top: 24px;
      }

      p {
        color: #66716c;
        padding: 6px 0;
      }

      .btn {
        justify-content: center;
        margin-top: 10px;

        .el-button {
          width: 102px;
        }

        .el-button:nth-of-type(1) {
          margin-right: 20px;
        }
      }

      .el-button {
        margin-top: 16px;
        width: 132px;
      }
    }
  }

  /deep/ .el-cascader {
    .el-input__inner {
      background: rgba(0, 0, 0, 0.03);
    }
  }
}

.el-input__suffix {
  right: 60px;
}
</style>
